<template>
  <div class="home">
    <top-header :btn_name="'Hisobot Yuklash'" :is_chech_out="true" :btn_icon="btn_icon"
                @edit-todo="generateReport()"></top-header>
    <Header></Header>
    <div class="table_container">
      <div class="docs_table">
        <div class="docs_form">
          <div class="docs_label">
            Hisobotingizni kiritng
          </div>
          <form @submit.prevent="createDocs()">
            <div class="doc_input">


              <div class="inputs my-4">
                <div>
                  <label>Izoh</label>
                </div>
                <input type="text" v-model="comment">
              </div>

              <div class="inputs inputs_quil">
                <div>
                  <label>To'liq ma'lumot</label>
                </div>
                <quill-editor
                    :required="true"
                    ref="myQuillEditorOrg"
                    v-model="about"
                />
              </div>

              <button class="create_btn" v-if="!edit_funcs">
                <b-spinner variant="white" v-if="isLoading"></b-spinner>
                <span v-if="!isLoading">Saqlash</span>
              </button>
              <a class="btn btn-primary create_btn" @click="editFunc()" v-if="edit_funcs">
                <b-spinner variant="white" v-if="isLoading"></b-spinner>
                <span v-if="!isLoading">O'zgartirish</span>
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "../../components/Header";
import TopHeader from "../../components/TopHeader";

export default {
  components: {TopHeader, Header},
  data() {
    return {
      btn_icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M10 4.375C8.34473 4.375 6.90674 5.14893 5.87891 6.28906C5.79346 6.2793 5.71777 6.25 5.625 6.25C4.25049 6.25 3.125 7.37549 3.125 8.75C2.04346 9.40918 1.25 10.5225 1.25 11.875C1.25 13.938 2.93701 15.625 5 15.625H8.125V14.375H5C3.61328 14.375 2.5 13.2617 2.5 11.875C2.5 10.8374 3.12988 9.95117 4.02344 9.57031L4.45312 9.39453L4.39453 8.92578C4.38232 8.82324 4.375 8.76465 4.375 8.75C4.375 8.05176 4.92676 7.5 5.625 7.5C5.71289 7.5 5.81055 7.51221 5.91797 7.53906L6.30859 7.63672L6.54297 7.32422C7.34375 6.29639 8.59375 5.625 10 5.625C12.0483 5.625 13.7573 7.03369 14.2383 8.92578L14.3555 9.41406L14.8828 9.39453C15.0146 9.38721 15.0439 9.375 15 9.375C16.3867 9.375 17.5 10.4883 17.5 11.875C17.5 13.2617 16.3867 14.375 15 14.375H11.875V15.625H15C17.063 15.625 18.75 13.938 18.75 11.875C18.75 9.90234 17.1948 8.32275 15.2539 8.18359C14.4897 5.98389 12.4536 4.375 10 4.375ZM10 9.375L7.5 11.875H9.375V16.875H10.625V11.875H12.5L10 9.375Z" fill="white"/> </svg>',
      about: '',
      check_date: [],
      isLoading: false,
      edit_funcs: false,
      edit_about: '',
      comment: '',

      main_date:new Date(),
    }
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
    createDocs() {
      this.isLoading = true
      var todayDate = new Date().toISOString().slice(0, 10);
      this.$axiosDefault.post(`/employees/${this.$store.state.user.id}/daily-reports/`, {
            "text": this.about,
            "date": todayDate,
            "employee": this.$store.state.user.id,
            "comment": this.comment
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          })
          .then(res => {
            this.$axiosDefault.post(`/employees/${this.$store.state.user.id}/monthly-reports/`, {
                  "month": todayDate,
                  "daily_reports":[
                    res.data.id
                  ]
                },
                {
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                  }
                })
            .then(month=>{
              this.$router.push(`employer/days/${month.data.id}`)
            })
            this.$axiosDefault.get('/employee/me', {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
            })
                .then(ress => {
                  console.log(ress)

                  this.isLoading = false
                  this.$axiosDefault.get(`employees/${ress.data.id}/daily-reports`,
                      {
                        headers: {
                          'Content-Type': 'application/json',
                          'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                      })
                      .then(res => {

                        this.check_date = res.data
                        this.about = ''
                        this.comment=''
                      })
                })
          })
          .catch(()=>{
            this.isLoading=false
          })
    },
    edit(item) {
      this.about = item.text
      this.edit_about = item
      this.edit_funcs = true

    },
    editFunc() {
      this.isLoading = true
      var todayDate = new Date().toISOString().slice(0, 10);
      this.$axiosDefault.put(`/employees/${this.$store.state.user.id}/daily-reports/${this.edit_about.id}/`, {
            "text": this.about,
            "date": todayDate,
            "employee": this.$store.state.user.id
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          })
          .then(res => {
            console.log(res)

            this.$axiosDefault.get('/employee/me', {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
            })
                .then(ress => {
                  console.log(ress)

                  this.isLoading = false
                  this.$axiosDefault.get(`employees/${ress.data.id}/daily-reports`,
                      {
                        headers: {
                          'Content-Type': 'application/json',
                          'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                      })
                      .then(res => {

                        this.check_date = res.data
                        this.about = ''
                        this.edit_funcs = false
                      })
                })
          })
    },
    deletes(item) {
      let isBoss = confirm("Rostan ham o'chirmoqchimisz?");
      if (isBoss == true) {
        this.$axiosDefault.delete(`/employees/${this.$store.state.user.id}/daily-reports/${item.id}/`,
            {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
            })

            .then(res => {
              console.log(res)

              this.$axiosDefault.get('/employee/me', {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
              })
                  .then(ress => {
                    console.log(ress)

                    this.isLoading = false
                    this.$axiosDefault.get(`employees/${ress.data.id}/daily-reports`,
                        {
                          headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                          }
                        })
                        .then(res => {

                          this.check_date = res.data
                          this.about = ''
                          this.edit_funcs = false
                        })
                  })
            })

      }
    },
  },
  mounted() {
    // console.log(this.main_date)
    // var today=this.main_date.toJSON().slice(0,10).split('-').reverse().join('-')

    this.$axiosDefault.get('/employee/me', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
        .then(ress => {
          console.log(ress)
          this.$axiosDefault.get(`/employees/${this.$store.state.user.id}/daily-reports/`,
              {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
              })
              .then(res => {
                this.check_date = res.data
                if (this.check_date[this.check_date.length - 1].check_out != null) {
                  this.is_out = true
                } else {
                  this.is_out = false
                }
              })
        })
  }
}
</script>
<style>

</style>
