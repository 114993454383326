<template>
  <div class="home">
    <div class="is_modal" v-if="is_modal">
      <div class="modal_card">
        <div class="modal_tex">
          Ish kuniningizni
          yakunlamoqchimsiz
        </div>
        <div class="modal_time">
          Qayd etiladigan vaqt: {{new Date().toLocaleTimeString()}}
        </div>
        <div class="modal_btns">
          <button class="exit_btn1" @click="check_out()">
            Ha
          </button>
          <button class="close_btm" @click="is_modal=false">
            Yo’q
          </button>
        </div>
      </div>

    </div>
    <top-header :btn_name="'Ishga keldim'" :is_chech_out="is_out" :btn_icon="btn_icon" @edit-todo="attendances()" @edit-todo1="is_modal=true" :btn_name_1="'Ishdan ketdim'" :btn_icon1="btn_icon1"></top-header>
    <Header></Header>
    <div class="table_container">
      <div class="table_main">
        <div class="table_title">
          <div class="t_title">Ishga kelib ketish vaqti</div>
          <div class="t_desc"> 09:00-18:00</div>
        </div>

        <!--          <div  class="table_buttons">-->
        <!--            <button>-->

        <!--              <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--                <path d="M6.51562 0.140625L7.23438 0.859375L2.09375 6L7.23438 11.1406L6.51562 11.8594L1.01562 6.35938L0.671875 6L1.01562 5.64062L6.51562 0.140625Z" fill="#303030"/>-->
        <!--              </svg>-->

        <!--            </button>-->
        <!--            <div class="year_main">-->
        <!--              <span>Fevral</span>, 2022-->
        <!--            </div>-->
        <!--            <button>-->

        <!--              <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--                <path d="M1.48438 0.140625L0.765625 0.859375L5.90625 6L0.765625 11.1406L1.48438 11.8594L6.98438 6.35938L7.32812 6L6.98438 5.64062L1.48438 0.140625Z" fill="#303030"/>-->
        <!--              </svg>-->

        <!--            </button>-->
        <!--          </div>-->
      </div>
      <div class="tables">
        <table>
          <thead>
          <tr>
            <th>№</th>
            <th>Sanasi</th>
            <th>Kelish vaqti</th>
            <th>Ketish vaqti</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,index) in check_date" :key="index">
            <td>{{index+1}}</td>
            <td>{{item.date}}</td>
            <td>{{new Date(item.check_in).toLocaleTimeString()}}</td>
            <td>
              <span v-if="item.check_out!=null">{{new Date(item.check_out).toLocaleTimeString()}}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "../components/Header";
import TopHeader from "../components/TopHeader";

export default {
  components: {TopHeader, Header},
  data(){
    return{
      is_modal:false,
      btn_icon:" <svg width=\"18\" height=\"18\" viewBox=\"0 0 18 18\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
          "            <path d=\"M9 0.875C4.52002 0.875 0.875 4.52002 0.875 9C0.875 13.48 4.52002 17.125 9 17.125C13.48 17.125 17.125 13.48 17.125 9C17.125 4.52002 13.48 0.875 9 0.875ZM9 2.125C12.8037 2.125 15.875 5.19629 15.875 9C15.875 12.8037 12.8037 15.875 9 15.875C5.19629 15.875 2.125 12.8037 2.125 9C2.125 5.19629 5.19629 2.125 9 2.125ZM8.375 5.25V8.375H5.25V9.625H8.375V12.75H9.625V9.625H12.75V8.375H9.625V5.25H8.375Z\" fill=\"white\"/>\n" +
          "          </svg>",
      check_date:[],
      btn_icon1:"\n" +
          "<svg width=\"20\" height=\"20\" viewBox=\"0 0 20 20\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
          "<path d=\"M10 1.875C5.52002 1.875 1.875 5.52002 1.875 10C1.875 14.48 5.52002 18.125 10 18.125C14.48 18.125 18.125 14.48 18.125 10C18.125 5.52002 14.48 1.875 10 1.875ZM10 3.125C13.8037 3.125 16.875 6.19629 16.875 10C16.875 13.8037 13.8037 16.875 10 16.875C6.19629 16.875 3.125 13.8037 3.125 10C3.125 6.19629 6.19629 3.125 10 3.125ZM6.25 9.375V10.625H13.75V9.375H6.25Z\" fill=\"#505D69\"/>\n" +
          "</svg>\n",
      is_out:true
    }
  },
  methods:{
    attendances(){
      var todayDate = new Date().toISOString().slice(0, 10);
      this.$axiosDefault.post(`/employees/${this.$store.state.user.id}/attendances/`,{
            "check_in":new Date(),
            "date":todayDate,
            "employee":this.$store.state.user.id
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          })
          .then(res=>{
            console.log(res)
            this.$axiosDefault.get('/employee/me',{
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
            })
                .then(ress=>{
                  console.log(ress)
                  this.$axiosDefault.get(`employees/${ress.data.id}/attendances`,
                      {
                        headers: {
                          'Content-Type': 'application/json',
                          'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                      })
                      .then(res=>{
                        this.check_date=res.data
                        if(this.check_date[this.check_date.length-1].check_out!=null){
                          this.is_out=true
                        }
                        else {
                          this.is_out=false
                        }
                      })
                })
          })
          .catch(()=>{
            this.$toast("Bugunlik ish vazifasini bajarib bo'ldiz maroqli hordiq tilaymiz", {
              timeout: 10000,
              type:"error"
            });
          })
    },
    check_out(){
      var todayDate = new Date().toISOString().slice(0, 10);
      this.$axiosDefault.patch(`/employees/${this.$store.state.user.id}/attendances/${this.check_date[this.check_date.length-1].id}/`,{
            "check_out":new Date(),
            "date":todayDate,
            "employee":this.$store.state.user.id
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          })
          .then(res=>{
            console.log(res)
            this.$axiosDefault.get('/employee/me',{
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
            })
                .then(()=>{
                  localStorage.removeItem('token')
                  this.$router.push('/login')
                })
          })
    }

  },
  mounted() {
    this.$axiosDefault.get('/employee/me',{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
        .then(ress=>{
          console.log(ress)
          this.$axiosDefault.get(`employees/${ress.data.id}/attendances`,
              {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
              })
              .then(res=>{
                this.check_date=res.data
                if(this.check_date[this.check_date.length-1].check_out!=null){
                  this.is_out=true
                }
                else {
                  this.is_out=false
                }
              })
        })
  }
}
</script>
