<template>
  <div class="home">
    <top-header :btn_name="'Hisobot yuklash'" :is_chech_out="true" :btn_icon="btn_icon"  ></top-header>

    <Header></Header>
    <div class="table_container">
      <div class="table_main">
        <div class="table_title">
          <div class="t_title">Ishga kelib ketish vaqti</div>
          <div class="t_desc"> Ish vaqti soat 9:00-18:00 gacha</div>
        </div>

<!--        <div  class="table_buttons">-->
<!--          <date-picker v-model="main_date" @input="func()"></date-picker>-->
<!--        </div>-->
      </div>
      <div class="tables">
        <table>
          <thead>
          <tr>
            <th>№</th>
            <th>F.I.O</th>
            <th>Bo'lim</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,index) in check_date" :key="index">
            <td>{{index+1}}</td>
            <td>
              <router-link :to="`/employer/docs/${item.id}`">{{item.first_name}} {{item.last_name}}</router-link>
            </td>
            <td>
              {{item.title}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "../components/Header";
import TopHeader from "../components/TopHeader";
import 'vue2-datepicker/index.css';

export default {
  components: {TopHeader, Header},
  data(){
    return{
      btn_icon:'<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M10 4.375C8.34473 4.375 6.90674 5.14893 5.87891 6.28906C5.79346 6.2793 5.71777 6.25 5.625 6.25C4.25049 6.25 3.125 7.37549 3.125 8.75C2.04346 9.40918 1.25 10.5225 1.25 11.875C1.25 13.938 2.93701 15.625 5 15.625H8.125V14.375H5C3.61328 14.375 2.5 13.2617 2.5 11.875C2.5 10.8374 3.12988 9.95117 4.02344 9.57031L4.45312 9.39453L4.39453 8.92578C4.38232 8.82324 4.375 8.76465 4.375 8.75C4.375 8.05176 4.92676 7.5 5.625 7.5C5.71289 7.5 5.81055 7.51221 5.91797 7.53906L6.30859 7.63672L6.54297 7.32422C7.34375 6.29639 8.59375 5.625 10 5.625C12.0483 5.625 13.7573 7.03369 14.2383 8.92578L14.3555 9.41406L14.8828 9.39453C15.0146 9.38721 15.0439 9.375 15 9.375C16.3867 9.375 17.5 10.4883 17.5 11.875C17.5 13.2617 16.3867 14.375 15 14.375H11.875V15.625H15C17.063 15.625 18.75 13.938 18.75 11.875C18.75 9.90234 17.1948 8.32275 15.2539 8.18359C14.4897 5.98389 12.4536 4.375 10 4.375ZM10 9.375L7.5 11.875H9.375V16.875H10.625V11.875H12.5L10 9.375Z" fill="white"/> </svg>',
      check_date:'',
      main_date:new Date(),
      time1: null,

    }
  },
  methods:{
    func(){

      console.log(this.main_date)
      var today=this.main_date.toJSON().slice(0,10).split('-').reverse().join('-')

      console.log(today)
      this.$axiosDefault.get('/employee/me',{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
          .then(ress=>{
            console.log(ress)
            this.$axiosDefault.get(`employees-attendances?date=${today}`,
                {
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                  }
                })
                .then(res=>{
                  this.check_date=res.data
                })
          })
    },
    editTodo(){
      this.$router.push('/create_work')
    }
  },
  mounted() {
    // console.log(new Date().toJSON().slice(0,10).split('-').reverse().join('-'))
    // var today=this.main_date.toJSON().slice(0,10).split('-').reverse().join('-')
    this.$axiosDefault.get('/employee/me',{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
        .then(ress=>{
          console.log(ress)
          this.$axiosDefault.get(`employees-info`,
              {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
              })
              .then(res=>{
                this.check_date=res.data
              })
        })
  }

}
</script>
