  <template>
    <div class="home">
      <top-header :btn_name="'Hisobot Yuklash'" :is_chech_out="true" :btn_icon="btn_icon"
                  @edit-todo="generateReport()"></top-header>
      <Header></Header>
      <div class="table_container">

        <div class="table_main">
          <div class="table_title">
            <div class="t_title">Hisobot yuritish bo’limi</div>
            <div class="t_desc"> {{ new Date().toLocaleDateString() }}</div>
          </div>


          <!--        <div  class="pdf_buttons">-->
          <!--          <button>-->
          <!--            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
          <!--              <path d="M8 3.5C9.32422 3.5 10.4746 4.11914 11.2969 5.03125C11.3652 5.02344 11.4258 5 11.5 5C12.5996 5 13.5 5.90039 13.5 7C14.3652 7.52734 15 8.41797 15 9.5C15 11.1504 13.6504 12.5 12 12.5H9.5V11.5H12C13.1094 11.5 14 10.6094 14 9.5C14 8.66992 13.4961 7.96094 12.7812 7.65625L12.4375 7.51562L12.4844 7.14062C12.4941 7.05859 12.5 7.01172 12.5 7C12.5 6.44141 12.0586 6 11.5 6C11.4297 6 11.3516 6.00977 11.2656 6.03125L10.9531 6.10938L10.7656 5.85938C10.125 5.03711 9.125 4.5 8 4.5C6.36133 4.5 4.99414 5.62695 4.60938 7.14062L4.51562 7.53125L4.09375 7.51562C3.98828 7.50977 3.96484 7.5 4 7.5C2.89062 7.5 2 8.39062 2 9.5C2 10.6094 2.89062 11.5 4 11.5H6.5V12.5H4C2.34961 12.5 1 11.1504 1 9.5C1 7.92188 2.24414 6.6582 3.79688 6.54688C4.4082 4.78711 6.03711 3.5 8 3.5ZM8 7.5L10 9.5H8.5V13.5H7.5V9.5H6L8 7.5Z" fill="#303030"/>-->
          <!--            </svg>-->
          <!--            <span>Faylni yuklash</span>-->
          <!--          </button>-->
          <!--        </div>-->
        </div>
        <div class="docs_table">
          <div class="tables">

            <table>
              <thead>
              <tr>
                <th>№</th>
                <th>Amallar</th>
                <th>Hisobot mazmuni</th>
                <th>Izoh</th>
                <th>Hisobot yozilgan vaqt</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,ind) in check_date" :key="ind">
                <td>{{ ind + 1 }}</td>
                <td class="action_btn">

                  <button @click="edit(item)">

                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M3.5 2.01562C3.88281 2.01562 4.25781 2.16406 4.54688 2.45312L9.5 7.39062L9.60938 7.5L9.64062 7.65625L9.98438 9.40625L10.1406 10.1406L9.40625 9.98438L7.65625 9.64062L7.5 9.60938L7.39062 9.5L2.45312 4.54688C1.87695 3.9707 1.87695 3.0293 2.45312 2.45312C2.74219 2.16406 3.11719 2.01562 3.5 2.01562ZM3.5 2.98438C3.38281 2.98438 3.26758 3.04492 3.15625 3.15625C2.93359 3.37891 2.93359 3.62109 3.15625 3.84375L8 8.6875L8.85938 8.85938L8.6875 8L3.84375 3.15625C3.73242 3.04492 3.61719 2.98438 3.5 2.98438ZM14 4V14H4V7.40625L5 8.40625V13H13V5H8.40625L7.40625 4H14Z"
                          fill="#303030"/>
                    </svg>

                  </button>
                  <button @click="deletes(item)">

                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M8.5 2C8.76172 2 9.0293 2.0918 9.21875 2.28125C9.4082 2.4707 9.5 2.73828 9.5 3V3.5H12.5V4.5H12V12.5C12 13.3223 11.3223 14 10.5 14H4.5C3.67773 14 3 13.3223 3 12.5V4.5H2.5V3.5H5.5V3C5.5 2.73828 5.5918 2.4707 5.78125 2.28125C5.9707 2.0918 6.23828 2 6.5 2H8.5ZM8.5 3H6.5V3.5H8.5V3ZM11 4.5H4V12.5C4 12.7773 4.22266 13 4.5 13H10.5C10.7773 13 11 12.7773 11 12.5V4.5ZM10 6V11.5H9V6H10ZM8 6V11.5H7V6H8ZM6 6V11.5H5V6H6Z"
                          fill="#303030"/>
                    </svg>

                  </button>
                </td>
                <td >
                  <div v-html="item.text"></div>
                </td>
                <td v-html="item.comment"></td>
                <td>{{ new Date(item.date).toLocaleDateString() }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="docs_form">
            <div class="docs_label">
              Hisobotingizni kiritng
            </div>
            <form @submit.prevent="createDocs()">
              <div class="doc_input">


                <div class="inputs my-4">
                  <div>
                    <label>Izoh</label>
                  </div>
                  <input type="text" v-model="comment">
                </div>

                <div class="inputs my-4">
                  <input type="file" class="form-control"
                         id="info_img"
                         @change="trySubmitFile"
                         accept="*"
                         name="datafiles"
                         ref="datafiles"
                  >
                </div>
                <div class="inputs inputs_quil">
                  <div>
                    <label>To'liq ma'lumot</label>
                  </div>
                  <quill-editor
                      :required="true"
                      ref="myQuillEditorOrg"
                      v-model="about"
                  />
                </div>
                <button class="create_btn" v-if="!edit_funcs">
                  <b-spinner variant="white" v-if="isLoading"></b-spinner>
                  <span v-if="!isLoading">Saqlash</span>
                </button>
                <a class="btn btn-primary create_btn" @click="editFunc()" v-if="edit_funcs">
                  <b-spinner variant="white" v-if="isLoading"></b-spinner>
                  <span v-if="!isLoading">O'zgartirish</span>
                </a>
              </div>
            </form>
          </div>
        </div>
        <div class="pdf_tabels">
          <vue-html2pdf
              :show-layout="false"
              :float-layout="false"
              :enable-download="false"
              :preview-modal="true"
              :paginate-elements-by-height="2500"
              filename="hisobot"
              :pdf-quality="2"
              :manual-pagination="false"
              pdf-format="a4"
              pdf-orientation="portrait"
              pdf-content-width="100%"
              ref="html2Pdf"
          >
            <div class="main_text_pdf" slot="pdf-content">

              <div class="table_mail">
                <div class="main_pdf_text">
                  <div>
                    «IT INVESTMENTS CENTER” {{$store.state.user.role_title}}
                    <br>
                    {{$store.state.user.first_name}} {{$store.state.user.last_name}} tomonidan {{new Date().toLocaleDateString()}}  bajarilgan
                    ishlar
                  </div>
                </div>

                <h2 class="text-center my-2">Hisobot</h2>

                <table>
                  <tr>
                    <th>№</th>
                    <th>Bajarilgan ishlar</th>
                    <th>Izoh</th>
                  </tr>
                  <tr v-for="(ite,ind) in check_date" :key="ind">
                    <td>{{ ind + 1 }}</td>
                    <td>
                      <div v-html="ite.text"></div>
                    </td>
                    <td v-html="ite.comment"></td>
                  </tr>
                </table>
              </div>
            </div>
          </vue-html2pdf>
        </div>
      </div>
    </div>
  </template>
  <script>
  import Header from "../components/Header";
  import TopHeader from "../components/TopHeader";

  export default {
    components: {TopHeader, Header},
    data() {
      return {
        btn_icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M10 4.375C8.34473 4.375 6.90674 5.14893 5.87891 6.28906C5.79346 6.2793 5.71777 6.25 5.625 6.25C4.25049 6.25 3.125 7.37549 3.125 8.75C2.04346 9.40918 1.25 10.5225 1.25 11.875C1.25 13.938 2.93701 15.625 5 15.625H8.125V14.375H5C3.61328 14.375 2.5 13.2617 2.5 11.875C2.5 10.8374 3.12988 9.95117 4.02344 9.57031L4.45312 9.39453L4.39453 8.92578C4.38232 8.82324 4.375 8.76465 4.375 8.75C4.375 8.05176 4.92676 7.5 5.625 7.5C5.71289 7.5 5.81055 7.51221 5.91797 7.53906L6.30859 7.63672L6.54297 7.32422C7.34375 6.29639 8.59375 5.625 10 5.625C12.0483 5.625 13.7573 7.03369 14.2383 8.92578L14.3555 9.41406L14.8828 9.39453C15.0146 9.38721 15.0439 9.375 15 9.375C16.3867 9.375 17.5 10.4883 17.5 11.875C17.5 13.2617 16.3867 14.375 15 14.375H11.875V15.625H15C17.063 15.625 18.75 13.938 18.75 11.875C18.75 9.90234 17.1948 8.32275 15.2539 8.18359C14.4897 5.98389 12.4536 4.375 10 4.375ZM10 9.375L7.5 11.875H9.375V16.875H10.625V11.875H12.5L10 9.375Z" fill="white"/> </svg>',
        about: '',
        check_date: [],
        isLoading: false,
        edit_funcs: false,
        edit_about: '',
        comment: '',

        main_date:new Date(),
        imageUrl:[],
        myImages:[],
        files_name:[],

      }
    },
    methods: {

      trySubmitFile(e) {
        this.imageUrl=[]
        for (let i = 0; i < e.target.files.length; i++) {
          this.files_name.push(e.target.files[i])
        }
        var files = Array.from(e.target.files);
        console.log(typeof (files))
        files.forEach((file) => {
          this.myImages.push(file);
          var reader = new FileReader();
          var vm = this;
          reader.onload = function(e) {
            vm.imageUrl.push(e.target.result);
          };
          reader.readAsDataURL(file);
        });
      },
      generateReport() {
        this.$refs.html2Pdf.generatePdf()
      },
      createDocs() {
        console.log(this.myImages[0])
        this.isLoading = true
        var todayDate = new Date().toISOString().slice(0, 10);

        const form = new FormData();
        form.append("text", this.about);
        form.append("date", todayDate);
        form.append("employee", this.$store.state.user.id);
        form.append("comment", this.comment);
        this.$axiosDefault.post(`/employees/${this.$store.state.user.id}/daily-reports/`, form,
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
            })
            .then(res => {
              var day_id=[]
              this.check_date.forEach(el=>{
                day_id.push(el.id,res.data.id)
              })
              this.$axiosDefault.put(`/employees/${this.$store.state.user.id}/monthly-reports/${this.$route.params.id}/`, {
                    "month": todayDate,
                    "daily_reports":day_id
                  },
                  {
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                  })
              this.$axiosDefault.get('/employee/me', {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
              })
                  .then(ress => {
                    console.log(ress)

                    this.isLoading = false
                    this.$axiosDefault.get(`/employees/${this.$store.state.user.id}/monthly-reports/${this.$route.params.id}`,
                        {
                          headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                          }
                        })
                        .then(res => {

                          this.check_date = res.data.daily_reports
                          this.about = ''
                          this.comment=''
                        })
                  })
            })
            .catch(()=>{
              this.isLoading=false
            })
      },
      edit(item) {
        this.about = item.text
        this.comment=item.comment
        this.edit_about = item
        this.edit_funcs = true

      },
      editFunc() {
        this.isLoading = true
        var todayDate = new Date().toISOString().slice(0, 10);
        this.$axiosDefault.put(`/employees/${this.$store.state.user.id}/daily-reports/${this.edit_about.id}/`, {
              "text": this.about,
              "date": todayDate,
              "employee": this.$store.state.user.id,
                "comment":this.comment
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
            })
            .then(res => {
              console.log(res)

              this.$axiosDefault.get('/employee/me', {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
              })
                  .then(ress => {
                    console.log(ress)

                    this.isLoading = false
                    this.$axiosDefault.get(`/employees/${this.$store.state.user.id}/monthly-reports/${this.$route.params.id}`,
                        {
                          headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                          }
                        })
                        .then(res => {

                          this.check_date = res.data.daily_reports
                          this.about = ''
                          this.edit_funcs = false
                          this.comment=''
                        })
                  })
            })
      },
      deletes(item) {
        let isBoss = confirm("Rostan ham o'chirmoqchimisz?");
        if (isBoss == true) {
          this.$axiosDefault.delete(`/employees/${this.$store.state.user.id}/daily-reports/${item.id}/`,
              {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
              })

              .then(res => {
                console.log(res)

                this.$axiosDefault.get('/employee/me', {
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                  }
                })
                    .then(ress => {
                      console.log(ress)

                      this.isLoading = false
                      this.$axiosDefault.get(`/employees/${this.$store.state.user.id}/monthly-reports/${this.$route.params.id}`,
                          {
                            headers: {
                              'Content-Type': 'application/json',
                              'Authorization': `Bearer ${localStorage.getItem('token')}`
                            }
                          })
                          .then(res => {

                            this.check_date = res.data.daily_reports
                            this.about = ''
                            this.edit_funcs = false
                          })
                    })
              })

        }
      },
    },
    mounted() {
      // console.log(this.main_date)
      // var today=this.main_date.toJSON().slice(0,10).split('-').reverse().join('-')

      this.$axiosDefault.get('/employee/me', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
          .then(ress => {
            console.log(ress)
            this.$axiosDefault.get(`/employees/${this.$store.state.user.id}/monthly-reports/${this.$route.params.id}`,
                {
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                  }
                })
                .then(res => {
                  this.check_date = res.data.daily_reports
                  if (this.check_date[this.check_date.length - 1].check_out != null) {
                    this.is_out = true
                  } else {
                    this.is_out = false
                  }
                })
          })
    }
  }
  </script>
  <style>

  </style>
